<!--
 * @Author       : JiangChao
 * @Date         : 2023-04-05 10:11:22
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-05 10:29:04
 * @Description  : 商業合作
-->
<template>
    <div class="container">
        <van-image
            class="custom-image"
            :src="require('@/assets/imgs/png/icon_content_partner.png')"
        ></van-image>
        <div style="margin-left: 50px; margin-right: 65px">
            <div style="color: #666666">感謝您使用旅電</div>
            <div style="color: #666666">
                若您對旅電有任何商業合作上的需求
                歡迎您來信/來電，我們將盡快與您聯絡 感謝
            </div>

            <div style="margin-top: 50px">
                <div class="image-style">
                    <van-image
                        width="20"
                        height="20"
                        :src="
                            require('@/assets/imgs/png/icon_version_time.png')
                        "
                    ></van-image>
                    <div class="desc">10:00-18:00</div>
                </div>
                <div class="image-style">
                    <van-image
                        width="20"
                        height="20"
                        :src="require('@/assets/imgs/png/icon_version_tel.png')"
                    ></van-image>
                    <div class="desc">02-2926-6681</div>
                </div>

                <div class="image-style">
                    <van-image
                        width="20"
                        height="20"
                        :src="
                            require('@/assets/imgs/png/icon_version_mailbox.png')
                        "
                    ></van-image>
                    <div class="desc">pluginn.info@gmail.com</div>
                </div>
            </div>
        </div>
        <div class="bottom">
              <van-image
                        width="50"
                        height="7"
                        :src="
                            require('@/assets/imgs/png/pluginn.png')
                        "
                    ></van-image>
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {};
    },
    methods: {},
    props: {},
    components: {},
    computed: {},
    mounted() {},
    created() {},
};
</script>

<style scoped lang="less">
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-image {
        width: 150px;
        margin-top: 120px;
        margin-bottom: 20px;
    }
    .image-style {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        .desc {
            margin-left: 10px;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
        }
    }
    .bottom{
        position: fixed;
        bottom: 0;
        margin-bottom: 20px;
    }
}
</style>
